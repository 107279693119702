import http from '@/libs/http'
import { useStore } from '@/store'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INFORMES + '/ventas'
export default class VentasServices {
  ovDetalle (params) {
    return http.get(`${baseUrl}/ov-detalle`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  downloadovDetalle (params) {
    const store = useStore()
    const token = store.getters['auth/getToken']
    params.token = `Bearer ${token}`
    return http.getUri({ url: `${baseUrl}/download-ov-detalle`, params })
  }
}
